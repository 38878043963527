<style scoped lang="less">
.valve-basic-information-body {
  display: flex;
  margin: 10px 0;

  .el-card {
    /* margin: 0 10px; */
    margin-right: 10px;
  }

  .el-card:last-child {
    margin: 0;
  }

  .input-form-card {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .input-form-item {
    min-width: 28%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;

    .input_has_unit {
      width: 200px;
      margin: 0;
    }

    .input_has_unit_text {
      margin: 0;
      width: 280px;
    }

    .el-date-editor.el-input {
      width: 200px;
    }
  }

  .input-form-item {
    min-width: 30%;
    margin: 0.4rem 0 0.4rem 0.5rem;
    display: flex;
    align-items: center;
  }

  .input-form-item-input {
    flex: 1;
  }

  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 40%;
    }
  }

  .input-form-item-label {
    width: 120px;
  }
}
</style>

<template>
  <div class="valve-basic-information-body">
    <!-- 冠脉 -->
    <el-card style="width: 33%" :body-style="{ padding: '20px' }">
      <div class="input-form-card">
        <div class="input-form-item">
          <div class="input-form-item-label">术式</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/LC_operation_method"
            :value.sync="aorticInfo.operativeStyle"
          />
        </div>
        <div class="input-form-item">
          <div class="input-form-item-label">用药方式</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/LC_treatment_for"
            :value.sync="aorticInfo.medicationMode"
          />
        </div>
        <div class="input-form-item">
          <div class="input-form-item-label">造影血管</div>
          <cqt-select
            :max="999"
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/LC_imaging_of_blood_vessel"
            :value.sync="aorticInfo.angiographicVessel"
          />
        </div>
        <div class="input-form-item" v-show="aorticInfo.angiographicVessel?.includes('其他')">
          <div class="input-form-item-label">造影血管其他</div>
          <div>
            <cqt-input
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              v-model="aorticInfo.angiographicVesselOther"
              size="mini"
              :isReadonly="isReadonly"
            ></cqt-input>
          </div>
        </div>
        <div class="input-form-item">
          <div class="input-form-item-label">造影表现</div>
          <cqt-select
            :max="999"
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/LC_image_manifestations"
            :value.sync="aorticInfo.imageManifestations"
          />
        </div>
        <div class="input-form-item" v-show="syncBtnIsShow">
          <div class="input-form-item-label">联合治疗</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/LC_combination_therapy"
            :value.sync="aorticInfo.combinationTherapy"
          />
        </div>

        <div class="input-form-item" v-show="syncBtnIsShow">
          <div class="input-form-item-label">门静脉癌栓分型</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/LC_portal_vein"
            :value.sync="aorticInfo.portalVeinClassification"
          />
        </div>
        <div class="input-form-item" v-show="syncBtnIsShow">
          <div class="input-form-item-label">肝静脉癌栓分型</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/LC_hepatic_vein"
            :value.sync="aorticInfo.hepaticVeinClassification"
          />
        </div>
        <div class="input-form-item">
          <div class="input-form-item-label">转移部位</div>
          <cqt-input
            :isReadonly="isReadonly"
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            size="mini"
            v-model="aorticInfo.transferSite"
          />
        </div>
      </div>
    </el-card>
    <!-- 血管 -->
    <el-card style="width: 33%" :body-style="{ padding: '20px' }">
      <div class="input-form-card">
        <div class="input-form-item">
          <div class="input-form-item-label">动脉入路</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/LC_puncture"
            :value.sync="aorticInfo.arterialApproach"
          />
        </div>
        <div class="input-form-item">
          <div class="input-form-item-label">是否使用CBCT</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/Yes_No"
            :value.sync="aorticInfo.useCbct"
          />
        </div>
        <div class="input-form-item">
          <div class="input-form-item-label">鞘管型号</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/LC_sheath_tube_type"
            :value.sync="aorticInfo.sheathModel"
          />
        </div>

        <div class="input-form-item">
          <div class="input-form-item-label">麻醉方式</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/AO_anesthesia"
            :value.sync="aorticInfo.anesthesiaType"
          />
        </div>

        <div class="input-form-item">
          <div class="input-form-item-label">造影剂</div>
          <div>
            <cqt-input
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              @input="getcontrastAgent"
              v-model="aorticInfo.contrastAgent"
              size="mini"
              unit="ml"
              min="0"
              max="300"
              :isReadonly="isReadonly"
            >
              <template slot="append">ml</template>
            </cqt-input>
          </div>
        </div>

        <div class="input-form-item">
          <div class="input-form-item-label">射线量</div>
          <div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              @input="getrayQuantity"
              v-model="aorticInfo.rayQuantity"
              size="mini"
              unit="mGy"
              min="0"
              max="500"
            >
              <template slot="append">mGy</template>
            </cqt-input>
          </div>
        </div>

        <div class="input-form-item">
          <div class="input-form-item-label">曝光时间</div>
          <div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              v-model="aorticInfo.exposureTime"
              size="mini"
              unit="min"
              min="0"
            >
              <template slot="append">min</template>
            </cqt-input>
          </div>
        </div>
      </div>
    </el-card>
    <!-- 出血 -->
    <el-card style="width: 33%" :body-style="{ padding: '20px' }">
      <div class="input-form-card">
        <div class="input-form-item">
          <div class="input-form-item-label">是否出血</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/Yes_No"
            :value.sync="aorticInfo.isBleeding"
          />
        </div>
        <div class="input-form-item" v-if="aorticInfo.isBleeding === '是'">
          <div class="input-form-item-label">出血量</div>
          <div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              @input="getoutTransAmount"
              v-model="aorticInfo.outTransAmount"
              size="mini"
              unit="ml"
              min="0"
              max="5000"
            >
              <template slot="append">ml</template>
            </cqt-input>
          </div>
        </div>

        <!-- 输血 -->
        <Transfusion
          :isReadonly="isReadonly"
          ref="Transfusion"
          :apiOptions="apiOptions"
          :surgeryInfo="aorticInfo"
        />

        <div class="input-form-item">
          <div class="input-form-item-label">鱼精蛋白</div>
          <cqt-radio :value.sync="aorticInfo.protamine" :isReadonly="isReadonly">
            <el-radio label="有">有</el-radio>
            <el-radio label="无">无</el-radio>
          </cqt-radio>
        </div>
        <div class="input-form-item" v-show="aorticInfo.protamine && aorticInfo.protamine !== '无'">
          <div class="input-form-item-label">鱼精蛋白剂量</div>
          <div>
            <cqt-input
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              @input="getprotamineDose"
              v-model="aorticInfo.protamineDose"
              size="mini"
              unit="ml"
              min="0"
              max="500"
              :isReadonly="isReadonly"
            ></cqt-input>
          </div>
        </div>

        <!-- 血管闭合方式 -->
        <BloodCloseType
          :isReadonly="isReadonly"
          ref="BloodCloseType"
          :apiOptions="apiOptions"
          :surgeryInfo="aorticInfo"
        />

        <div class="input-form-item">
          <div class="input-form-item-label">术后制动</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/LC_BrakAreaTime"
            :value.sync="aorticInfo.postOperativeBraking"
          />
        </div>

        <!-- <div class="input-form-item">
          <div class="input-form-item-label">特殊操作</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/LC_Intervention"
            :value.sync="aorticInfo.intervention"
          />
        </div> -->
      </div>
    </el-card>
    <!-- 特殊操作 -->
    <!-- <el-card style="width: 25%" :body-style="{ padding: '20px' }">
      <div class="input-form-card">
        <div class="input-form-item">
          <div class="input-form-item-label">特殊操作</div>
          <cqt-select :isReadonly="isReadonly" activeUrl="/v1/webconsole/comboData/get/gmzy_jbxx_Intervention"
            :value.sync="aorticInfo.intervention" />
        </div>
      </div>
    </el-card> -->
  </div>
</template>

<script>
// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'

import BloodCloseType from '../../components/BloodCloseType.vue'
import Transfusion from '../../components/Transfusion.vue'

export default {
  name: 'BasicForm',
  components: {
    CqtInput,
    CqtSelect,
    CqtRadio,
    BloodCloseType,
    Transfusion
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false
    },
    basicData: {
      type: Object
    }
  },
  data() {
    return {
      aorticInfo: {
        // anySignificantStenosis: null,
        // collateralVessels: null,
        approachType: null,
        conductionBlockRemark: null,
        conductionBlockRisk: null,
        contrastAgent: null,
        coronaryRemark: null,
        coronaryRisk: null,
        anesthesiaType: null,
        details: null,
        diagnosis: null,
        embolicRemark: null,
        embolicRisk: null,
        exposureTime: null,
        vesslClosure: null,
        vesselObturator: null,
        isBmsl: null,
        isHkz: null,
        isYkz: null,
        isBleeding: '否', // 是否出血
        outTransAmount: null,
        protamine: null,
        protamineDose: null,
        rapidPacing: null,
        rayQuantity: null,
        recordCode: null,
        recordDate: null,
        recordName: null,
        riskOtherRemark: null,
        ruptureAnnulus: null,
        ruptureRemark: null,
        sheathGoInTime: null,
        sheathLength: null,
        sheathOutTime: '',
        sheathSize: null,
        shzd: null,
        remark: null,
        superhardGW: null,
        transfusion: null,
        transfusionAmount: null,
        transfusionComponent: null,
        nnisRating: null,
        ultrasonic: null,
        vascularApproach: null,
        coronaryDominantType: null,
        malformationOfCoronary: null,
        postOperativeBraking: null,
        // 定制化
        operativeStyle: null,
        medicationMode: null,
        angiographicVesselOther: null,
        imageManifestations: null,
        tumorTreatment: null,
        portalVein: null,
        aggressiveTreatment: null,
        combinationTherapy: null,
        vesslClosureOther: null
      },
      apiOptions: {
        vesslClosureUrl: 'LC_vessel_closure', // 血管闭合方式
        vesselObturatorUrl: 'LC_vessel_obturator', // 闭合器
        occluder_typeUrl: 'occluder_type', // 封堵器
        stapler_typeUrl: 'stapler_type', // 缝合器
        transfusionComponentUrl: 'AO_composition_blood_transfusion' // 输血成分
      },
      syncBtnIsShow: false
    }
  },
  computed: {},
  watch: {
    basicData: {
      handler(val) {
        this.aorticInfo = { ...this.aorticInfo, ...val }
      },
      immediate: true,
      deep: true
    },
    'aorticInfo.angiographicVessel': {
      handler(val) {
        if (!val?.includes('其他')) {
          this.aorticInfo.angiographicVesselOther = null
        }
      }
    }
  },
  mounted() {},
  created() {
    this.getSyncIsShow()
  },
  methods: {
    getSyncIsShow() {
      let data = {
        code: 'TACE_CLASSIFICATION_THERAPY_SHOW'
      }
      this.$api.get(`/v1/webconsole/sys/defaultValue`, data).then((res) => {
        if (res.data && res.data.data) {
          console.log(res.data.data)
          if (res.data.data === '是') {
            this.syncBtnIsShow = true
          } else {
            this.syncBtnIsShow = false
          }
        }
      })
    },
    geiSheathOutTime(val) {
      if (!this.aorticInfo.sheathGoInTime) return
      const time = this.differMin(this.aorticInfo.sheathGoInTime, val)
      if (time < 0) {
        this.aorticInfo.sheathOutTime = null
        this.$message({
          message: '鞘拔除时间应大于鞘置入时间',
          type: 'warning'
        })
      }
    },
    getcontrastAgent() {
      if (this.aorticInfo.contrastAgent) {
        if (this.aorticInfo.contrastAgent > 300) this.aorticInfo.contrastAgent = 300

        if (this.aorticInfo.contrastAgent < 0) this.aorticInfo.contrastAgent = 0
      }
    },
    getrayQuantity() {
      if (this.aorticInfo.rayQuantity) {
        if (this.aorticInfo.rayQuantity > 500) this.aorticInfo.rayQuantity = 500

        if (this.aorticInfo.rayQuantity < 0) this.aorticInfo.rayQuantity = 0
      }
    },
    getoutTransAmount() {
      if (this.aorticInfo.outTransAmount) {
        if (this.aorticInfo.outTransAmount > 5000) this.aorticInfo.outTransAmount = 5000

        if (this.aorticInfo.outTransAmount < 0) this.aorticInfo.outTransAmount = 0
      }
    },
    getprotamineDose() {
      if (this.aorticInfo.protamineDose) {
        if (this.aorticInfo.protamineDose > 500) this.aorticInfo.protamineDose = 500

        if (this.aorticInfo.protamineDose < 0) this.aorticInfo.protamineDose = 0
      }
    },
    getAllBasicData() {
      this.aorticInfo = {
        ...this.aorticInfo,
        ...this.$refs.BloodCloseType.aorticInfo,
        ...this.$refs.Transfusion.aorticInfo
      }
      return this.aorticInfo
    }
  }
}
</script>
