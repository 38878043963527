<template>
  <div class="info-table">
    <div style="display: flex">
      <span class="inner-title">肿瘤信息{{ isReadonly ? '' : '录入' }}</span>
    </div>
    <div style="margin: 10px 0 0 10px">
      <div v-show="!isReadonly">
        <el-button
          size="mini"
          type="primary"
          class="commonBtn"
          @click="tumorInsert"
          :disabled="signed_status"
        >
          新增
        </el-button>
        <el-button
          size="mini"
          type="warning"
          class="commonBtn"
          @click="tumorModify"
          :disabled="signed_status"
        >
          修改
        </el-button>
        <el-button
          size="mini"
          type="danger"
          class="commonBtn"
          @click="tumorDelete"
          :disabled="signed_status"
        >
          删除
        </el-button>
      </div>

      <el-table
        class="inner-table"
        highlight-current-row
        :data="tumorTableData"
        :header-cell-style="{ padding: 0 }"
        height="200px"
        style="width: 99%"
        @row-click="_rowClick"
        @row-dblclick="_doubleModify"
        border
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="lesionLocation" label="病灶位置">
          <template slot-scope="scope">
            {{ scope.row.lesionLocation?.replace(/\|/g, '，') }}
          </template>
        </el-table-column>
        <el-table-column prop="feedingArtery" label="肿瘤供血动脉">
          <template slot-scope="scope">
            {{ scope.row.feedingArtery?.replace(/\|/g, '，') }}
          </template>
        </el-table-column>
        <el-table-column prop="perfusionArtery" label="灌注动脉">
          <template slot-scope="scope">
            {{ scope.row.perfusionArtery?.replace(/\|/g, '，') }}
          </template>
        </el-table-column>
        <el-table-column prop="perfusionDrug" label="灌注药物">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.liverCancerPerfusionDrug" :key="index">
              <span>{{ item.drugName + '，' }}</span>
              <span v-if="item.drugDose">{{ item?.drugDose + 'ml/hr' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="embolicArtery" label="栓塞动脉">
          <template slot-scope="scope">
            {{ scope.row.embolicArtery?.replace(/\|/g, '，') }}
          </template>
        </el-table-column>
        <el-table-column prop="embolicDrugs" label="栓塞类型和治疗效果">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.liverCancerEmbolicDrug" :key="index">
              <span v-if="item.embolismType">{{ item.embolismType + '，' }}</span>
              <span v-if="item.treatmentEffect">{{ item.treatmentEffect + '，' }}</span>
              <span v-if="item.embolicAgentType">
                {{ '栓塞剂：' + item.embolicAgentType + item.embolicAgentDosage + 'ml，' }}
              </span>
              <span v-if="item.coilCount">{{ '植入弹簧圈数量：' + item.coilCount + '，' }}</span>
              <span v-if="item.drugName">{{ item.drugName + '，' }}</span>
              <span v-if="item.drugDose">{{ item?.drugDose + 'ml/hr' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="120" prop="handlingOpinion" label="本次治疗">
          <template slot-scope="scope">
            {{ scope.row.handlingOpinion?.replace(/\|/g, '，') }}
          </template>
        </el-table-column>
      </el-table>
      <tumor-info-dialog
        ref="tumorInfoDialog"
        :liverCancerId="liverCancerId"
        :isReadonly="isReadonly"
        @save="_dialogSave"
      ></tumor-info-dialog>
    </div>
  </div>
</template>

<script>
import TumorInfoDialog from './TumorInfoDialog.vue'
export default {
  name: 'LiverCancerTumorInformation',
  components: {
    TumorInfoDialog
  },

  props: {
    liverCancerId: {
      type: String,
      require: true
    },
    signed_status: {
      type: Boolean,
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    },
    allDetail: {
      type: Object,
      default: () => ({})
    },
    formId: {
      type: String,
      require: true
    }
  },
  watch: {
    liverCancerId: {
      handler(val) {
        if (val) {
        }
      },
      immediate: true
    },
    allDetail: {
      handler(val) {
        if (val) {
          this._getAllBloodVesselDetail()
        }
      }
    },
    selectedInfo: {
      handler(val) {
        if (val) {
          this.getAllSuggestions(val)
        }
      }
    }
  },
  data() {
    return {
      tumorTableData: [],
      selectedInfo: {},
      tableKey: null
    }
  },
  created() {},
  methods: {
    _getAllBloodVesselDetail() {
      let url = `/v1/webconsole/eform/extendfields/${this.formId}/${this.allDetail.liverCancerTumorInformation.id}`
      let formData = new FormData()
      formData.append('elementName', 'liverCancerTumorInformation')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            this.tumorTableData = res.data.map((item) => ({
              ...item,
              ids: Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000)
            }))
            for (let i = 0; i < this.tumorTableData.length; i++) {
              const element = this.tumorTableData[i]
              // 处理意见handlingSuggestions表示有二级分类
              if (element.handlingOpinion) {
                this.getAllSuggestions(element, 'init', i)
              }
            }
          }
        },
        () => {}
      )
    },

    getAllSuggestions(val, type, index) {
      if (val.handlingOpinion?.includes('灌注')) {
        this._getLiverCancerPerfusionDrugInfo(val, type, index)
      }
      if (val.handlingOpinion?.includes('栓塞')) {
        this._getLiverCancerEmbolicDrugInfo(val, type, index)
      }
    },

    // 灌注药物
    async _getLiverCancerPerfusionDrugInfo(val, type, index) {
      // 轮询赋值
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'liverCancerPerfusionDrug')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.liverCancerPerfusionDrug = res.data.data || []
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        val.liverCancerPerfusionDrug = this.selectedInfo.liverCancerPerfusionDrug
      }

      this.$set(this.tumorTableData, index, val)
    },
    // 栓塞药物
    async _getLiverCancerEmbolicDrugInfo(val, type, index) {
      // 轮询赋值
      if (type === 'init') {
        try {
          const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.id}`
          let formData = new FormData()
          formData.append('elementName', 'liverCancerEmbolicDrug')
          const res = await this.$api.post(url, formData)
          if (res.data && res.data.data && res.data.data.length) {
            val.liverCancerEmbolicDrug = res.data.data || []
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        val.liverCancerEmbolicDrug = this.selectedInfo.liverCancerEmbolicDrug
      }

      this.$set(this.tumorTableData, index, val)
    },

    tumorInsert() {
      this.$refs.tumorInfoDialog.Show()
    },
    tumorModify() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条肿瘤信息')
      if (this.selectedInfo) {
        this.$refs.tumorInfoDialog.Show(this.selectedInfo)
      }
    },
    tumorDelete() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条肿瘤信息')
      this.$confirm('是否删除该肿瘤信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._delete()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    _delete() {
      if (this.selectedInfo) {
        if (this.selectedInfo.ids) {
          for (let i = 0; i < this.tumorTableData.length; i++) {
            const element = this.tumorTableData[i]
            if (this.selectedInfo.ids === element.ids) {
              this.tumorTableData.splice(i, 1)
              break
            }
          }
        } else {
          return this.$message.warning('当前肿瘤信息不存在')
        }
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.selectedInfo = null
      }
    },
    _rowClick(row) {
      this.selectedInfo = null
      this.selectedInfo = row
    },
    _doubleModify(row) {
      this.selectedInfo = null
      this.selectedInfo = row
      this.$refs.tumorInfoDialog.Show(this.selectedInfo)
    },
    _dialogSave(val) {
      if (!val.ids) {
        this.tumorTableData.push({
          ...val,
          ids: Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 1000)
        })
      } else {
        for (let i = 0; i < this.tumorTableData.length; i++) {
          const element = this.tumorTableData[i]
          if (val.ids === element.ids) {
            this.tumorTableData[i] = val
            this.$set(this.tumorTableData, i, this.tumorTableData[i])
          }
        }
      }
      this.selectedInfo = null
    }
  }
}
</script>

<style scoped lang="less">
.info-table {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
  /deep/.el-table .cell {
    white-space: normal;
  }
  /deep/.top-item-row .item-row span {
    margin: 0 0 0 5px;
  }
  button {
    margin-right: 0.5rem;
  }
}
.inner-title {
  position: absolute;
  top: -12px;
  left: 12px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}
.inner-table {
  margin-top: 10px;
  border: 1px solid black;
  height: 200px;
  position: relative;
  left: 5px;
}
.inner-tabs {
  margin: 10px 10px;
  height: 250px;
}
.item-inline {
  margin: 15px 25px;
  display: flex;
  justify-content: flex-end;
}
.item-inline > span {
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
}
.item-column {
  display: flex;
  flex-direction: column;
}
</style>
