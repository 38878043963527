<template>
  <div class="valve-module-main-content main-content" v-loading="loading">
    <div class="crt-content">
      <div class="save-button" v-show="!isReadonly">
        <el-button
          @click="open_save"
          size="mini"
          type="success"
          class="commonBtn"
          :disabled="isReadonly"
        >
          保存
        </el-button>
      </div>

      <el-button @click="isReadonly = !isReadonly" size="mini" class="commonBtn" type="primary">
        {{ isReadonly === true ? '编辑' : '取消' }}
      </el-button>
      <el-button
        icon="el-icon-s-platform"
        @click="infoPreview"
        size="mini"
        class="commonBtn"
        type="primary"
      >
        报告预览
      </el-button>
      <el-button
        icon="el-icon-edit"
        @click="reportSign"
        size="mini"
        class="commonBtn"
        type="success"
      >
        {{ signStatus ? '解签' : '报告签名' }}
      </el-button>
    </div>
    <div class="body">
      <div class="valve-basic-information">
        <div class="valve-basic-information-body" style="margin: 10px 0">
          <BasicForm :isReadonly="isReadonly" :basicData="coronaryInfo" ref="BasicForm" />
        </div>
      </div>

      <div class="liver-cancer-tumor-information">
        <liver-cancer-tumor-information
          :allDetail="allDetail"
          :formId="formData.formId"
          v-model="liverCancerId"
          :isReadonly="isReadonly"
          :signed_status="isReadonly"
          ref="LiverCancerTumorInformation"
        ></liver-cancer-tumor-information>
      </div>

      <!-- 并发症 -->
      <ComplicationForm
        :isReadonly="isReadonly"
        :complicationData="coronaryInfo"
        complicationUrl="/v1/webconsole/comboData/get/LC_complication"
        finalDiagnosisUrl="/v1/webconsole/comboData/get/LC_post_diagnosis"
        ref="ComplicationForm"
      />
      <!-- 手术经过 -->
      <div class="surgery-procedure">
        <surgery-procedure
          :isReadOnly="isReadonly"
          :templateData="templateProcedureData"
          :surgeryInfo="surgeryInfo?.studyProcess"
          :operation="operation"
          ref="SurgeryProcedure"
          @infoPreview="infoPreview"
        ></surgery-procedure>
      </div>
      <!-- 手术结果 -->
      <!-- <div class="surgery-result">
        <surgery-result
          :isReadOnly="isReadonly"
          :templateData="templateResultData"
          :surgeryInfo="surgeryInfo?.studyResult"
          :operation="operation"
          ref="result"
        ></surgery-result>
      </div> -->
      <!-- 术后诊疗计划 -->
      <div class="post-operative-medical-diagnosis">
        <post-medical-diagnosis
          :isReadOnly="isReadonly"
          :templateData="templateDiagnosisData"
          :surgeryInfo="surgeryInfo"
          :operation="operation"
          ref="PostMedicalDiagnosis"
        />
      </div>
      <!-- 签名 -->
      <sign-report :operation="operation" @signComplete="signComplete" ref="SignReport" />
      <el-dialog title="报告详情" :visible.sync="dialogVisible" width="70%" :modal="false">
        <div>
          <iframe :src="this.report_address" style="height: 700px; width: 100%"></iframe>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import SurgeryProcedure from '../components/SurgeryProcedure.vue'
import SurgeryResult from '../components/SurgeryResult.vue'
import PostMedicalDiagnosis from '../components/PostMedicalDiagnosis.vue'
import LiverCancerTumorInformation from './components/LiverCancerTumorInformation.vue'
import BasicForm from './components/BasicForm.vue'
import ComplicationForm from '../components/ComplicationForm.vue'
import SignReport from '../components/SignReport.vue'

// import { mapState } from 'vuex'

// cqt
import CqtInput from '../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../components/cqt/CqtRadio.vue'

import { PDF_HOST } from '../../../utils/globalVariable'

export default {
  components: {
    SurgeryProcedure,
    SurgeryResult,
    PostMedicalDiagnosis,
    BasicForm,
    LiverCancerTumorInformation,
    ComplicationForm,
    SignReport,
    CqtSelect,
    CqtInput,
    CqtRadio
  },
  data() {
    return {
      report_address: '',
      dialogVisible: false,
      reportInfo: null,
      signStatus: false,
      loading: true,
      coronaryInfo: {},
      menuListData: null,
      universalForm: [],
      formList: [],
      allDetail: {},
      formData: {
        formId: '',
        id: '',
        items: []
      },
      universalStudy: {
        // 公共字段
        postDiagnosis: undefined, //术后诊断
        studyProcess: undefined, //手术经过
        // studyResult: undefined, //手术结果
        o2: undefined, //吸氧方式
        anesthesiaType: undefined, //麻醉方式
        vascularApproach: undefined, //血管入路
        otherApproachType: undefined, // 其他入路方式
        approachType: undefined, //入路方式
        superHardGW: undefined, //超硬导丝
        contrastAgent: undefined, //造影剂剂量(ml)
        rayQuantity: undefined, //射线量(mGy)
        exposureTime: undefined, //曝光时间(min)
        sheathSize: undefined, //鞘管大小
        sheathGoInTime: undefined, //鞘置入时间
        sheathOutTime: undefined, //鞘拔除时间
        sheathLength: undefined, //鞘置入时长 min
        isBleeding: '否', // 是否出血
        outTransAmount: undefined, //出血量
        transfusion: undefined, //是否输血
        transfusionComponent: undefined, //输血成分
        transfusionAmount: undefined, //输血量
        protamine: undefined, //鱼精蛋白
        protamineDose: undefined, //鱼精蛋白剂量（mg）
        vesslClosure: undefined, //血管闭合方式
        vesselObturator: undefined, //血管闭合器种类
        vesselObturatorOther: undefined, //血管闭合器种类其他
        intervention: undefined, //特殊操作
        pacingThreshold: undefined, //临时起搏阈值
        pacingFrequency: undefined, //临时起搏频率
        pacingOutput: undefined, //临时起搏输出
        pacingPerception: undefined, //临时起搏感知
        patientFeedback: undefined, //术后患者反映
        skinExamination: undefined, //术后皮肤检查
        vascularPulsation: undefined, //术后穿刺处血管搏动
        postOperativeBraking: undefined, //术后制动
        transferMethod: undefined, //转运方式
        postoperativeDestination: undefined, //术后去向
        riskAssessment: undefined, //风险评估
        complication: undefined, //并发症
        postAdvice: undefined, //术后医嘱
        postNotes: undefined, //术后注意事项
        treatmentPlanId: undefined, //术后诊疗计划id
        treatmentPlan: undefined, //术后诊疗,

        otherTransfusionComponent: undefined, //其他输血成分描述,
        wholeBloodVolume: undefined, //全血输血量,
        plasmaVolume: undefined, //血浆输血量,
        plateletVolume: undefined, //血小板输血量,
        redBloodCellVolume: undefined, //红细胞输血量,
        otherVolume: undefined, //其他成分血输血量,
        otherSuperHardGW: null, // 超硬导丝其他描述
        vesslClosureOther: undefined, // 血管闭合方式其他
        // complicationOther: undefined, // 并发症其他
        // postDiagnosisOther: undefined, // 术后诊断其他

        occluderType: undefined, // 封堵器类型
        occluderOtherType: undefined, // 封堵器类型其他
        staplerType: undefined, // 缝合器类型
        staplerOtherType: undefined, // 缝合器类型其他
        pressureTime: undefined // 压迫时间
      },
      isReadonly: false,
      // 手术经过结果信息
      operationName: '肝癌介入',
      surgeryInfo: null,
      operation: 'LiverCancer',
      templateProcedureData: {
        templateName: '手术经过'
      },
      templateResultData: {
        templateName: '手术结果'
      },
      templateAdviceData: {
        templateName: '术后医嘱'
      },
      templateDiagnosisData: {
        templateName: '术后诊疗计划'
      },

      liverCancerId: '-1',
      selectedRow: null,
      tableKey: null,
      cancerInfo: {
        firstTACE: null, //首次TACE日期
        punctureSite: null, //穿刺部位
        operativeStyle: null, //术式
        medicationMode: null //用药方式
      },
      liverCancerPreInspectionInfo: {
        liverFunction: null, //肝功能
        routineBlood: null, //血常规
        tumorMarker: null, //肿瘤标注物
        hepatitis: null //肝炎
      },
      liverCancerPathologyInfo: {
        ascites: null, //是否活检
        pathologicalType: null, //病理类型
        primaryLiverCancer: null, //原发性肝癌
        metastaticLiverCancer: null //转移性肝癌
      },
      liverCancermRecistInfo: {
        firstTargetLesion: null, //1st.靶病灶疗效
        secondTargetLesion: null, //2nd.靶病灶疗效
        thirdTargetLesion: null, //3rd.靶病灶疗效
        lastTargetLesion: null //末次靶病灶疗效
      },
      liverCancerEvaluationInfo: {
        portalVein: null, //有无门静脉癌栓治疗
        aggressiveTreatment: null, //胆道或肝静脉侵犯治疗
        tumorTreatment: null, //肝外转移瘤治疗
        followUpTreatment: null, //后续治疗方案
        survivalState: null, //生存状态
        dateOfDeath: null, //死亡日期
        causeOfDeath: null, //死亡原因
        survivalTime: null, //生存时间
        survivalTimeUnit: null, //生存时间单位
        combinationTherapy: null, //有无联合其他治疗
        taceComplications: null //TACE栓塞后并发症
      },
      coronaryDetail: {
        elementId: 'liverCancerTumorInformation',
        value: null,
        extendTable: null
      }
    }
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.uuid) {
          this.getMenuIndex()
        }
      },
      immediate: true
    }
  },

  mounted() {},
  created() {},
  methods: {
    getMenuIndex() {
      if (this.$store.state.menuList.length > 0 && !this.$route.path.includes('follow')) {
        for (let i = 0; i < this.$store.state.menuList.length; i++) {
          const element = this.$store.state.menuList[i]
          if (element.menuCode.includes(this.$route.path)) {
            this.menuListData = element
          }
        }
        this.loading = true
        this.getTable()
      } else {
        let url = `/v1/webconsole/study/operation/menu/${this.info.uuid}`
        this.$api.get(url).then((res) => {
          let menuList = []
          if (res.data && res.data.data) {
            menuList = res.data.data.map((item) => {
              return {
                ...item,
                menuCode: `/Detail/${item.menuCode}`,
                code: item.menuCode
              }
            })
            for (let i = 0; i < menuList.length; i++) {
              const element = menuList[i]
              if (
                element.menuCode.includes(
                  JSON.parse(localStorage.getItem('followPath')).replace('/follow/info', '')
                )
              ) {
                this.menuListData = element
              }
            }
            this.getTable()
          }
        })
      }
    },
    getTable() {
      let url = `/v1/webconsole/eform/universal/form/${this.info.uuid}/${this.menuListData.moduleId}`
      this.$api.get(url).then(({ data: res }) => {
        if (res && res.data) {
          this.universalForm = res.data
          this.formData.formId = res.data[0].formId
          let formUrl = `/v1/webconsole/eform/universal/formdata/${this.info.uuid}/${this.menuListData.moduleId}`
          let data = {
            formId: this.formData.formId
          }
          this.$api.get(formUrl, data).then(({ data: res }) => {
            if (res && res.data) {
              // 如果没有数据则使用初始化
              if (res.data.length !== 0) {
                this.formList = res.data
                this.formData.id = res.data[0].id
                this.getTableData()
              }
              this.getReportInfo()
            }
          })

          // 查询基本数据
          this.$api
            .get(
              `/v1/webconsole/study/operation/universal/${this.info.uuid}?moduleCode=${this.menuListData.code}`,
              data
            )
            .then(({ data: res }) => {
              if (res && res.data) {
                // 如果没有数据则使用初始化
                if (res.data) {
                  let obj = {}
                  for (let i in res.data) {
                    for (let j in this.universalStudy) {
                      if (j === i) {
                        obj[i] = res.data[i]
                      }
                    }
                  }
                  this.coronaryInfo = { ...this.coronaryInfo, ...obj }
                  this.surgeryInfo = obj
                }
              }
            })
        }
      })
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    // 获取表单数据
    getTableData() {
      let url = `/v1/webconsole/eform/fields/${this.formList[0].formId}/${this.formList[0].id}`
      this.$api.get(url).then(({ data: res }) => {
        let obj = {}
        if (res && res.data) {
          for (let i in res.data) {
            obj[i] = res.data[i].valueString
            if (res.data[i].dataType === 'dataTable') {
              res.data[i].extendTable = []
            }
          }
          this.coronaryInfo = { ...this.coronaryInfo, ...obj }
          this.allDetail = res.data
        }
      })
    },
    getReportInfo() {
      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.reportInfo = res.data.data
            if (res.data.data?.signed === 'Y') {
              this.signStatus = true
            }
          }
        })
    },
    // 签名
    reportSign() {
      // 解签
      if (this.signStatus) {
        this.$refs.SignReport.showRelievePwdDialog()
      } else {
        this._save(() => {
          this.$refs.SignReport.getReportSignType()
        })
      }
    },
    signComplete(val) {
      this.signStatus = val
    },
    // 报告
    infoPreview() {
      const pdfLoading = this.$loading({
        lock: true,
        text: '报告加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: false
      })
      this.report_address = null

      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then(
          (res) => {
            pdfLoading.close()
            if (!res.data.data || res.data.data?.signed === 'N') {
              // 生成手术报告单
              this._save(() => {
                this.$api
                  .get(
                    `/v1/webconsole/report/save/doctor/${this.info.uuid}/${this.menuListData.code}`
                  )
                  .then(
                    (response) => {
                      pdfLoading.close()
                      if (response.data && response.data.data) {
                        if (!response.data.data.pdfFileName) {
                          return this.$message.info('暂无报告')
                        }
                        var s = encodeURI(response.data.data.pdfFileName)
                        if (response.data.data.pdfFileName.includes('http')) {
                          this.report_address = s
                        } else {
                          this.report_address =
                            PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
                        }
                        this.dialogVisible = true
                      } else {
                        return this.$message.info('暂无报告')
                      }
                    },
                    () => {
                      pdfLoading.close()
                      return this.$message.error('生成报告出错！')
                    }
                  )
                  .catch((err) => {
                    pdfLoading.close()
                    return this.$message.error('生成报告出错！')
                  })
              })
            } else {
              var s = encodeURIComponent(res.data.data.pdfFileName)
              if (res.data.data.pdfFileName.includes('http')) {
                this.report_address = s
              } else {
                this.report_address = PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
              }
              this.dialogVisible = true
            }
          },
          () => {
            pdfLoading.close()
            return this.$message.error('生成报告出错！')
          }
        )
    },

    formatUtcTime(v) {
      if (v === null) {
        return ''
      }
      let date = new Date(v)
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '-' +
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
        ' ' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
      )
    },
    open_save() {
      if (this.isReadonly) {
        return this.$message({
          message: '报告已签名,无法进行编辑操作',
          type: 'warning'
        })
      }
      this.$confirm('是否保存肝癌TACE信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._save()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消保存'
          })
        })
    },
    _save(callback) {
      this.formData.items = []
      let aorticInfo = this.$refs.BasicForm.getAllBasicData() // 全部基础信息
      let complicationForm = this.$refs.ComplicationForm.aorticInfo
      complicationForm = {
        ...complicationForm,
        complication: complicationForm.complication?.join('|'),
        postDiagnosis: complicationForm.postDiagnosis || ''
      } // 并发症
      this.loading = true
      let differentFields = {}

      for (let ele in this.universalStudy) {
        // 按要求分割对象
        for (let item in aorticInfo) {
          if (item === ele) {
            this.universalStudy[ele] = aorticInfo[ele]
          }
        }
      }
      for (let item in aorticInfo) {
        // 剩下没有相同字段的对象
        if (!this.universalStudy?.hasOwnProperty(item)) {
          differentFields[item] = aorticInfo[item]
        }
      }

      differentFields = { ...differentFields }
      for (let i in differentFields) {
        this.formData.items.push({
          elementId: i,
          value: differentFields[i],
          extendTable: null
        })
      }
      this.formData.universalStudy = {
        // 基础字段
        ...this.universalStudy,
        ...complicationForm,
        moduleId: this.menuListData.moduleId,
        operationId: this.info.uuid,
        treatmentPlanId: this.$refs.PostMedicalDiagnosis.treatmentPlanId, // 术后诊疗计划
        treatmentPlan: this.$refs.PostMedicalDiagnosis.treatmentPlan, // 术后诊疗计划
        studyProcess: this.$refs.SurgeryProcedure.studyRecord, // 手术经过
        // studyResult: this.$refs.result.studyResult // 手术结果
      } // universalStudy字段
      this.handleData()
      let url = `/v1/webconsole/eform/universal/save/${this.info.uuid}/${this.menuListData.moduleId}`
      this.$api
        .post(url, this.formData)
        .then(
          ({ data: data }) => {
            if (data.status === 200) {
              this.liverCancerId = data.data.id
              this.loading = false
              this.$bus.$emit('saveFormInfo')
              return !callback && this.$message.success('保存成功')
            }
          },
          () => {
            this.loading = false
            return !callback && this.$message.error('保存出错')
          }
        )
        .finally(() => {
          callback && callback()
        })
    },
    handleData() {
      if (this.$refs.LiverCancerTumorInformation.tumorTableData?.length !== 0) {
        const Arr = this.$refs.LiverCancerTumorInformation.tumorTableData.map((element) =>
          this.convertElement(element)
        )

        if (this.allDetail.coronaryDetail) {
          this.allDetail.coronaryDetail.extendTable = Arr
          this.coronaryDetail.extendTable = this.allDetail.coronaryDetail.extendTable
        } else {
          this.coronaryDetail.extendTable = Arr
        }
        this.formData.items.push(this.coronaryDetail)
      }
    },
    convertElement(element) {
      delete element.id
      const arr = Object.entries(element).map(([i, value]) => {
        if (value && typeof value !== 'string') {
          let subDetail
          if (i === 'liverCancerPerfusionDrug' || i === 'liverCancerEmbolicDrug') {
            subDetail = element[i]?.map((ele) => {
              return Object.entries(ele).map(([k, v]) => ({
                elementId: k,
                value: v,
                extendTable: null
              }))
            })
          } else {
            subDetail = [
              Object.entries(value).map(([j, ele]) => ({
                elementId: j,
                value: ele,
                extendTable: null
              }))
            ]
          }
          return {
            elementId: i,
            value: null,
            extendTable: subDetail
          }
        } else {
          return {
            elementId: i,
            value: value,
            extendTable: null
          }
        }
      })
      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-finished::before {
  content: url('../../../assets/img/finished.png');
}
.icon-warn::before {
  content: url('../../../assets/img/warn.png');
}
.valve-module-main-content {
  .body::-webkit-scrollbar {
    width: 12px;
  }
  .body::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  .body::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
  }
  .body {
    flex: 1;
    overflow: auto;
    padding: 0 10px 20px;
    background: #eff0f7;

    .inner-title {
      padding: 5px;
    }
    .button-panel {
      width: 100%;
      bottom: 0;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
    }
    .valve-basic-information-border {
      margin-top: 20px;
      margin-bottom: 30px;
      border-style: solid;
      border-width: medium;
      /* border-color: #c0c4cc; */
      padding: 20px 15px;
      border-radius: 5px;
      position: relative;
      border-color: #ecf0f1;
      background-color: #fff !important;
      box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
    }
    .valve-basic-information {
      // border-top: solid #f2f6fc;
      // border-bottom: solid #f2f6fc;
      // border-style: solid;
      // border-width: medium;
      // border-color: #f2f6fc;
      .inner-title {
        position: absolute;
        top: -12px;
        left: 12px;
        background: #fff;
        padding: 0 10px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-top: 5px;
      }
      .valve-basic-information-head {
        margin: 5px 0;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        .valve-basic-information-head-text {
          padding: 0 10px;
          font-size: 14px;
        }
        // .except-button {
        //   height: 3rem;
        //   line-height: 3rem;
        //   display: flex;
        //   .valve-basic-information-head-text {
        //     padding-left: 10px;
        //     padding-right: 10px;
        //     font-size: 14px;
        //   }
        // }
        .save-button {
          margin-right: 0.5rem;
          padding-left: 10px;
        }
      }
      .valve-basic-information-body {
        .input-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        .input-form-item-row {
          width: 100%;
          margin: 0 0.5rem;
          display: flex;
          justify-content: flex-start;
        }

        .input-form-item {
          min-width: 28%;
          margin: 0.4rem 1.5rem;
          display: flex;
          align-items: center;
          .input_has_unit {
            margin: 0;
          }
          .el-date-editor.el-input {
            width: 200px;
          }
        }
        @media screen and (max-width: 1180px) {
          .input-form-item {
            min-width: 40%;
          }
        }

        .input-form-item-label {
          width: 120px;
        }
      }
    }

    // .pre-expansions-number {
    //   border-style: solid;
    //   border-width: medium;
    //   border-color: #ebeef5;
    //   margin-top: 30px;
    //   margin-bottom: 30px;

    //   .pre-expansions-head {
    //     button {
    //       margin-right: 0.5rem;
    //     }
    //     display: flex;
    //     .title-row {
    //       padding-left: 10px;
    //     }
    //     .title-number {
    //       padding-left: 10px;
    //     }
    //     .title-button {
    //       padding-left: 10px;

    //       // bug

    //       padding-bottom: 5px;
    //     }
    //   }
    // }

    .post-expansions-number {
      border-style: solid;
      border-width: medium;
      border-color: #e4e7ed;
      margin-top: 30px;
      margin-bottom: 30px;

      .post-expansions-head {
        display: flex;
        button {
          margin-right: 0.5rem;
        }
        .title-row {
          padding-left: 10px;
        }
        .title-number {
          padding-left: 10px;
        }
        .title-button {
          padding-left: 10px;
          padding-bottom: 5px;
          padding-top: 5px;
        }
      }
    }
    // .valve-implants-number {
    //   border-style: solid;
    //   border-width: medium;
    //   border-color: #dcdfe6;
    //   margin-top: 30px;
    //   margin-bottom: 30px;

    //   .valve-implants-number-head {
    //     display: flex;
    //     button {
    //       margin-right: 0.5rem;
    //     }
    //     .title-row {
    //       padding-left: 10px;
    //     }
    //     .title-number {
    //       padding-left: 10px;
    //     }
    //     .title-button {
    //       padding-left: 10px;
    //       padding-bottom: 5px;
    //       padding-top: 5px;
    //     }
    //   }
    // }

    .valve-basic-information-muticards {
      // border-style: solid;
      // border-width: medium;
      // border-color: #c0c4cc;
      margin-top: 10px;
      margin-bottom: 30px;

      // .except-button {
      //   height: 3rem;
      //   line-height: 3rem;
      //   display: flex;
      //   .pacemaker-basic-information-head-text {
      //     padding-left: 10px;
      //     padding-right: 10px;
      //   }
      // }

      .save-button {
        padding-left: 10px;
      }
      .delete-button {
        padding-left: 10px;
      }

      .input-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      .input-form-item-row {
        width: 100%;
        margin: 0 0.5rem;
        display: flex;
        justify-content: flex-start;
      }

      .input-form-item {
        min-width: 28%;
        margin: 0.4rem 1.5rem;
        display: flex;
        align-items: center;
        .input_has_unit {
          margin: 0;
        }
        .el-date-editor.el-input {
          width: 200px;
        }
      }
      @media screen and (max-width: 1180px) {
        .input-form-item {
          min-width: 40%;
        }
      }

      .input-form-item-label {
        width: 120px;
      }
    }
  }
}
.save-button {
  padding-left: 10px;
  margin-right: 0.5rem;
}
</style>
