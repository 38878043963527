<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  /deep/.el-form-item__content {
    line-height: 0 !important;
  }
  .img-box {
    display: flex;
    align-items: center;
  }
  .icon-add {
    width: 30px;
    height: 30px;
    // position: absolute;
    // right: 8px;
    cursor: pointer;
  }
  .icon-delete {
    width: 27px;
    height: 27px;
    // position: absolute;
    // right: 2.5rem;
    cursor: pointer;
    margin-right: 12px;
  }
}

.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 24%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  justify-content: center;
  position: relative;
  .icon-add {
    width: 30px;
    height: 30px;
    position: absolute;
    // top: 0;
    right: 0;
    cursor: pointer;
  }
  .icon-delete {
    width: 27px;
    height: 27px;
    position: absolute;
    right: 2rem;
    cursor: pointer;
  }
}
.item-row-block {
  display: flex;
  width: 100%;

  .item-row-title {
    white-space: nowrap;
    margin-right: 10px;
    min-width: 100px;
  }
}

.item-row > span {
  margin: 0 15px 5px 0;
}

.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.input_has_unit {
  width: 190px;
  margin-left: 0;
}
.dialogContent::-webkit-scrollbar {
  width: 12px;
}
.dialogContent::-webkit-scrollbar-track {
  border-radius: 10px;
}
.dialogContent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.dialogContent::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}
.dialogContent {
  flex: 1;
  overflow: auto;
}
</style>

<template>
  <CmDialogBorder size="big" :zIndex="zIndex" title="肿瘤信息" v-show="isShow" @close="Close">
    <div class="crt-content">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
        v-show="!isReadonly"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
    <div class="dialogContent">
      <div class="top-item-row">
        <div class="item-row">
          <span>病灶位置</span>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/LC_lesion_location"
            :value.sync="tumorTableInfo.lesionLocation"
          />
        </div>
        <div class="item-row">
          <span>本次治疗</span>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/LC_handling_opinion"
            :value.sync="tumorTableInfo.handlingOpinion"
          />
        </div>
      </div>
      <div class="top-item-row">
        <div class="item-row-block">
          <div class="item-row-title">肿瘤供血动脉</div>
          <cqt-select
            :max="999"
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/LC_artery"
            :value.sync="tumorTableInfo.feedingArtery"
          />
        </div>
        <div class="item-row-block" v-show="tumorTableInfo.feedingArtery?.includes('其他')">
          <div class="item-row-title">肿瘤供血动脉其他</div>
          <div>
            <cqt-input
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              v-model="tumorTableInfo.feedingArteryOther"
              size="mini"
              :isReadonly="isReadonly"
            ></cqt-input>
          </div>
        </div>
      </div>
      <template v-if="tumorTableInfo.handlingOpinion?.includes('灌注')">
        <el-divider content-position="left">
          <span style="font-size: 18px; font-weight: bold">灌注</span>
        </el-divider>
        <div class="top-item-row">
          <div class="item-row-block">
            <div class="item-row-title">灌注动脉</div>
            <cqt-select
              :max="999"
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/LC_drug_artery"
              :value.sync="tumorTableInfo.perfusionArtery"
            />
          </div>
        </div>
        <el-form>
          <div class="top-item-row">
            <template v-for="(item, index) in gzTableData">
              <div class="item-row">
                <span>灌注药物</span>
                <el-form-item :error="item?.drugNameErrorMessage">
                  <cqt-select
                    :isReadonly="isReadonly"
                    activeUrl="/v1/webconsole/comboData/get/LC_drug_name"
                    :value.sync="item.drugName"
                    @update:value="selectChangeEvent(item.drugName, index, '灌注')"
                  />
                </el-form-item>
              </div>
              <div class="item-row">
                <span>灌注剂量</span>
                <el-form-item :error="item?.drugDoseErrorMessage">
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    unit="ml/hr"
                    v-model="item.drugDose"
                  />
                </el-form-item>
                <img
                  v-if="index === gzTableData.length - 1"
                  src="../../../../assets/img/iconAdd.png"
                  class="icon-add"
                  alt=""
                  @click="addRow('灌注')"
                />
                <img
                  v-if="index === gzTableData.length - 1 && index !== 0"
                  src="../../../../assets/img/iconDelete.png"
                  class="icon-delete"
                  alt=""
                  @click="deleteRow('灌注')"
                />
              </div>
            </template>
          </div>
        </el-form>
      </template>
      <template v-if="tumorTableInfo.handlingOpinion?.includes('栓塞')">
        <el-divider content-position="left">
          <span style="font-size: 18px; font-weight: bold">栓塞</span>
        </el-divider>
        <div class="top-item-row">
          <div class="item-row-block">
            <div class="item-row-title">栓塞动脉</div>
            <cqt-select
              :max="999"
              :isReadonly="isReadonly"
              activeUrl="/v1/webconsole/comboData/get/LC_drug_artery"
              :value.sync="tumorTableInfo.embolicArtery"
            />
          </div>
        </div>
        <el-form>
          <div class="top-item-row">
            <template v-for="(item, index) in ssTableData">
              <!-- eslint-disable-next-line -->
              <div class="item-row">
                <span>栓塞类型</span>
                <el-form-item :error="item?.embolismTypeErrorMessage">
                  <cqt-select
                    :isReadonly="isReadonly"
                    activeUrl="/v1/webconsole/comboData/get/LC_embolism_type"
                    :value.sync="item.embolismType"
                  />
                </el-form-item>
              </div>
              <!-- eslint-disable-next-line -->
              <div class="item-row">
                <span>治疗效果</span>
                <el-form-item :error="item?.treatmentEffectErrorMessage">
                  <cqt-select
                    :isReadonly="isReadonly"
                    activeUrl="/v1/webconsole/comboData/get/LC_treatment_effect"
                    :value.sync="item.treatmentEffect"
                  />
                </el-form-item>
              </div>
              <!-- eslint-disable-next-line -->

              <div class="item-row" v-if="item.embolismType?.includes('栓塞剂')">
                <span>栓塞剂类型</span>
                <el-form-item>
                  <cqt-select
                    :isReadonly="isReadonly"
                    activeUrl="/v1/webconsole/comboData/get/LC_embolic_agent"
                    :value.sync="item.embolicAgentType"
                  />
                </el-form-item>
              </div>
              <div class="item-row" v-if="item.embolismType?.includes('栓塞剂')">
                <span>栓塞剂用量</span>
                <el-form-item>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    unit="ml"
                    v-model="item.embolicAgentDosage"
                  />
                </el-form-item>
              </div>
              <div class="item-row" v-if="item.embolismType?.includes('弹簧圈栓塞')">
                <span>植入弹簧圈数量</span>
                <el-form-item>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    v-model="item.coilCount"
                  />
                </el-form-item>
              </div>
              <div class="item-row" v-if="item.embolismType?.includes('药物治疗')">
                <span>栓塞药物</span>
                <el-form-item>
                  <cqt-select
                    :isReadonly="isReadonly"
                    activeUrl="/v1/webconsole/comboData/get/LC_drug_name"
                    :value.sync="item.drugName"
                  />
                </el-form-item>
              </div>
              <div class="item-row" v-if="item.embolismType?.includes('药物治疗')">
                <span>栓塞剂量</span>
                <el-form-item>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    unit="ml/hr"
                    v-model="item.drugDose"
                  />
                </el-form-item>
              </div>
              <div
                class="item-row"
                v-else-if="item.embolismType && item.embolismType?.includes('弹簧圈栓塞')"
              ></div>
              <div
                class="item-row"
                v-if="item.embolismType && item.embolismType?.includes('栓塞剂&弹簧圈栓塞')"
              ></div>
              <div
                class="item-row"
                v-if="item.embolismType && item.embolismType?.includes('栓塞剂&弹簧圈栓塞')"
              ></div>
              <div class="img-box">
                <img
                  v-if="index === ssTableData.length - 1 && index !== 0"
                  src="../../../../assets/img/iconDelete.png"
                  class="icon-delete"
                  alt=""
                  @click="deleteRow('栓塞')"
                />
                <img
                  v-if="index === ssTableData.length - 1"
                  src="../../../../assets/img/iconAdd.png"
                  class="icon-add"
                  alt=""
                  @click="addRow('栓塞')"
                />
              </div>
            </template>
          </div>
        </el-form>
      </template>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'

export default {
  name: 'TumorInfoDialog',
  components: {
    CmDialogBorder,

    CqtSelect,
    CqtInput
  },
  props: {
    liverCancerId: {
      type: String,
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      albiExitActive: require('@/assets/img/nerve/albi.png'),
      albiIsExitHover: false, //是否悬浮到图标上
      childPughExitActive: require('@/assets/img/nerve/childPugh.png'),
      childPughIsExitHover: false, //是否悬浮到图标上
      ecogpsExitActive: require('@/assets/img/nerve/ecogps.png'),
      ecogpsIsExitHover: false, //是否悬浮到图标上
      cnlcExitActive: require('@/assets/img/nerve/cnlc.png'),
      cnlcIsExitHover: false, //是否悬浮到图标上
      bclcExitActive: require('@/assets/img/nerve/bclc.png'),
      bclcIsExitHover: false, //是否悬浮到图标上
      tnmExitActive: require('@/assets/img/nerve/tnm.png'),
      tnmIsExitHover: false, //是否悬浮到图标上
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      tumorTableInfo: {
        liverCancerId: '', //肝癌介入主表主键
        angiographicVessel: null, //造影血管
        feedingArtery: null, //肿瘤供血动脉
        feedingArteryOther: null, //肿瘤供血动脉
        perfusionArtery: null, //灌注动脉
        perfusionDrug: null, //灌注药物
        perfusionDose: null, //灌注剂量
        embolicArtery: null, //栓塞动脉
        lesionLocation: null, //栓塞病灶位置
        embolicDrugs: null, //栓塞药物
        embolicDose: null, //栓塞剂量
        albi: null, //ALBI分级
        childPugh: null, //Child-Pugh分级
        ecogps: null, //ECOG PS评分
        cnlc: null, //CNLC分期
        bclc: null, //BCLC分期
        tnm: null, //TNM分期
        transferSite: null, //转移部位
        handlingOpinion: null // 本次治疗
      },
      gzTableData: [
        { drugName: '', drugDose: '' },
        { drugName: '', drugDose: '' }
      ],
      ssTableData: [
        {
          embolismType: '',
          treatmentEffect: '',
          embolicAgentType: '',
          embolicAgentDosage: '',
          coilCount: '',
          drugName: '',
          drugDose: ''
        },
        {
          embolismType: '',
          treatmentEffect: '',
          embolicAgentType: '',
          embolicAgentDosage: '',
          coilCount: '',
          drugName: '',
          drugDose: ''
        }
      ]
    }
  },
  watch: {
    'tumorTableInfo.feedingArtery': {
      handler(val) {
        if (!val?.includes('其他')) {
          this.tumorTableInfo.feedingArteryOther = null
        }
      }
    }
  },
  created() {},
  methods: {
    Show(bloodVessel) {
      this.tumorTableInfo = this.$options.data().tumorTableInfo
      this.gzTableData = this.$options.data().gzTableData
      this.ssTableData = this.$options.data().ssTableData

      if (bloodVessel) this.tumorTableInfo = deepClone(bloodVessel)
      this.isShow = true
      this.gzTableData =
        this.tumorTableInfo?.liverCancerPerfusionDrug?.length > 0
          ? this.tumorTableInfo?.liverCancerPerfusionDrug
          : this.gzTableData
      this.ssTableData =
        this.tumorTableInfo?.liverCancerEmbolicDrug?.length > 0
          ? this.tumorTableInfo?.liverCancerEmbolicDrug
          : this.ssTableData
    },
    Save() {
      try {
        let errors = []
        this.tumorTableInfo.liverCancerPerfusionDrug = []
        this.tumorTableInfo.liverCancerEmbolicDrug = []
        if (this.tumorTableInfo.handlingOpinion?.includes('灌注')) {
          this.gzTableData = this.gzTableData?.map((element) => {
            element.drugDoseErrorMessage = ''
            element.drugNameErrorMessage = ''
            if (!element.drugDose && element.drugName) {
              element.drugDoseErrorMessage = '请输入灌注药物剂量'
              errors.push('请输入灌注药物名称和剂量')
            }
            if (!element.drugName && element.drugDose) {
              element.drugNameErrorMessage = '请输入灌注药物'
              errors.push('请输入灌注药物名称和剂量')
            }
            return element
          })
          this.tumorTableInfo.liverCancerPerfusionDrug = this.gzTableData?.filter(
            (item) => item.drugName && item.drugDose
          )
        }
        if (this.tumorTableInfo.handlingOpinion?.includes('栓塞')) {
          this.ssTableData = this.ssTableData?.map((element) => {
            // element.drugDoseErrorMessage = ''
            // element.drugNameErrorMessage = ''
            // if (!element.drugDose && element.drugName) {
            //   element.drugDoseErrorMessage = '请输入栓塞药物剂量'
            //   errors.push('请输入栓塞药物名称和剂量')
            // }
            // if (!element.drugName && element.drugDose) {
            //   element.drugNameErrorMessage = '请输入灌注药物'
            //   errors.push('请输入栓塞药物名称和剂量')
            // }

            element.embolismTypeErrorMessage = ''
            element.treatmentEffectErrorMessage = ''

            if (!element.embolismType && element.treatmentEffect) {
              element.embolismTypeErrorMessage = '请选择栓塞类型和治疗效果'
              errors.push('请选择栓塞类型和治疗效果')
            }
            if (!element.treatmentEffect && element.embolismType) {
              element.treatmentEffectErrorMessage = '请选择栓塞类型和治疗效果'
              errors.push('请选择栓塞类型和治疗效果')
            }
            return element
          })
          this.tumorTableInfo.liverCancerEmbolicDrug = this.ssTableData?.filter(
            (item) => item.embolismType && item.treatmentEffect
          )
        }
        if (errors.length > 0) return
        this.$emit('save', this.tumorTableInfo)
        console.log(this.tumorTableInfo)
        this.Close()
      } catch (err) {
        console.log(err)
      }
    },

    Close() {
      this.isShow = false
    },

    selectChangeEvent(e, itemIndex, type, valueType) {
      if (type == '灌注') {
        if (e) {
          const indexs = this.gzTableData.findIndex(
            (item, index) => item.drugName && item.drugName === e && index !== itemIndex
          )
          if (indexs !== -1) {
            this.$message.error('当前药物已存在！')
            this.$set(this.gzTableData[itemIndex], 'drugName', null)
          }
        }
      }
    },

    // 添加
    addRow(type) {
      if (type == '灌注') {
        let newObj = {
          drugName: '',
          drugDose: ''
        }
        this.gzTableData.push(newObj)
      }
      if (type == '栓塞') {
        let newObj = {
          embolismType: '',
          treatmentEffect: '',
          embolicAgentType: '',
          embolicAgentDosage: '',
          coilCount: '',
          drugName: '',
          drugDose: ''
        }
        this.ssTableData.push(newObj)
      }
    },
    deleteRow(type) {
      if (type == '灌注') {
        this.gzTableData.splice(this.gzTableData.length - 1, 1)
      }
      if (type == '栓塞') {
        this.ssTableData.splice(this.ssTableData.length - 1, 1)
      }
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  }
}
</script>
